<template>
  <DialogPortal>
    <DialogOverlay
      class="fixed left-0 top-0 z-[100] h-full w-full data-[state=open]:bg-black-600 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0" />
    <DialogContent v-bind="forwarded" :class="cn(dialogContentVariants({ size }), $attrs.class ?? '')">
      <slot />
    </DialogContent>
  </DialogPortal>
</template>

<script setup lang="ts">
import { DialogContent, type DialogContentEmits, DialogOverlay, DialogPortal, useForwardPropsEmits } from 'radix-vue'
import { cn } from '@/lib/utils'
import type { DialogContentProps } from '~/components/ui/dialog/types'
import { dialogContentVariants } from '.'

const props = defineProps<DialogContentProps>()
const emits = defineEmits<DialogContentEmits>()

const forwarded = useForwardPropsEmits(props, emits)
</script>
