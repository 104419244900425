<template>
  <DialogRoot v-bind="forwarded" v-model="open">
    <DialogTrigger as-child>
      <slot />
    </DialogTrigger>
    <DialogContent class="dialog-content" v-bind="dialogContentEvent" :size>
      <slot name="header">
        <DialogHeader class="dialog-header">
          <slot name="header-inner">
            <slot name="header-title">
              <h5 class="text-base font-semibold leading-none">
                {{ headerTitle }}
              </h5>
            </slot>
            <slot name="header-button" />
          </slot>
        </DialogHeader>
      </slot>

      <slot name="body">
        <DialogBody class="dialog-body">
          <slot name="body-inner">
            <p class="text-base font-normal">
              {{ bodyDescription }}
            </p>
          </slot>
        </DialogBody>
      </slot>

      <slot name="footer">
        <DialogFooter class="dialog-footer">
          <slot name="footer-inner">
            <div>
              <slot name="footer-left-button" />
            </div>
            <div>
              <slot name="footer-right-button" />
            </div>
          </slot>
        </DialogFooter>
      </slot>
    </DialogContent>
  </DialogRoot>
</template>

<script setup lang="ts">
import { camelCase, upperFirst } from 'lodash-es'
import { type DialogContentEmits, DialogRoot, type DialogRootEmits, useForwardPropsEmits } from 'radix-vue'
import { dialogEventPropKeys } from '~/components/ui/dialog/index'
import type { DialogProps } from './types'

const props = withDefaults(defineProps<DialogProps>(), {
  size: 'md',
  headerTitle: '',
  bodyDescription: '',
  closeAutoFocus: true,
  escapeKeyDown: false,
  focusOutside: true,
  interactOutside: false,
  openAutoFocus: true,
  pointerDownOutside: true
})
const emits = defineEmits<DialogRootEmits & DialogContentEmits>()
const open = defineModel<boolean>({ default: false })
const forwarded = useForwardPropsEmits(props, emits)

const dialogContentEvent: Record<string, (event: Event) => unknown> = {}
Object.entries(props)
  .filter(([propName, value]) => dialogEventPropKeys.includes(propName) && value === false)
  .forEach(([propName]) => {
    dialogContentEvent[`on${upperFirst(camelCase(propName))}`] = (event: Event) => event.preventDefault()
  })
</script>
